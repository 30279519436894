import React from "react"
import styled from "styled-components"
import Ingress from "./Ingress"
import Image from "./Image"
import { gutter } from "../vars"
import media from "../media"
import { getIdFromString } from "../utils"
import get from "lodash/get"
import AnimateIns from "./AnimateIns"
import Grid from "./Grid"

const Divider = styled.div`
  height: 1px;
  background: white;
  margin-bottom: ${gutter * 4}px;
  margin-top: ${gutter * 4}px;
`

const StyledIngress = styled(Ingress)`
  margin-bottom: 0;
`
const StyledImage = styled(Image)`
  margin-bottom: ${gutter * 2}px;

  ${media.phone`
    max-width: 130px;
    margin: 0 auto;
  `}
`
const Title = styled.h1`
  text-align: center;
  padding-top: ${gutter * 4}px;
`
const Container = styled.div``
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: ${gutter * 2}px;
  }

  ${media.desktop`
    @supports (display: grid) {
        grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
    }
  `}
`

const Body = styled.div``
const Step = styled.div`
  width: 22%;
  text-align: center;

  @supports (display: grid) {
    width: 100%;
  }

  &:first-child {
    width: 100%;

    @supports (display: grid) {
      grid-column: 1 / -1;
    }
  }

  ${media.desktop`
    width: 230px;

    @supports (display: grid) {
      width: 100%;
    }
  `}

  ${media.phone`
    width: 100%;
  `}
`

const AppBlock = ({ data }) => {
  const { heading, steps = [], menu_item } = data

  const fallbackAlt = get(heading, "text")

  return (
    <Grid>
      <Container id={getIdFromString(menu_item)}>
        <AnimateIns>
          {heading && <Title>{heading.text}</Title>}
          <Wrapper>
            {steps.map((step, i) => (
              <Step key={i}>
                {step.image && (
                  <StyledImage fallbackAlt={fallbackAlt} image={step.image} />
                )}
                <StyledIngress>
                  {i + 1}. {step.title && step.title.text}
                </StyledIngress>
                <Body>{step.body && step.body.text}</Body>
              </Step>
            ))}
          </Wrapper>
          <Divider />
        </AnimateIns>
      </Container>
    </Grid>
  )
}

export default AppBlock
