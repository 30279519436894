import React, { useEffect, useMemo, useRef, createRef, useState } from "react"
import styled from "styled-components"
import get from "lodash/get"
import SlideShow from "../SlideShow"
import "splitting/dist/splitting.css"
import { gutter } from "../vars"
import media from "../media"
import Image from "gatsby-image"

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
`

const HashTag = styled.div`
  position: absolute;
  left: 50%;
  z-index: 20;
  display: flex;
  transform: translateX(-50%);
  bottom: ${gutter * 8}px;
  opacity: 0;
  transition: opacity 0.4s ease;
`

const Inner = styled.div`
  height: 680px;
  position: relative;
  overflow: hidden;

  ${media.phone`
    height: 50vh;
  `}

  ${media.tablet`
    height: 500px;
  `}

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.12);
  }
`

const Title = styled.h1`
  font-weight: bold;
  opacity: 0;
  text-align: center;
  font-weight: 900;
  font-size: 72px;
  width: 100%;
  padding: 0 ${gutter}px;
  line-height: 1.1;
  letter-spacing: -1px;

  ${media.phone`
    font-size: 32px;
  `}

  ${media.tablet`
  font-size: 42px;
  `}

  .char {
    transform: scale(0);
    opacity: 1;
    display: inline !important;
  }

  .char::before,
  .char::after {
    display: none;
  }
`

const Item = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.4s ease;

  &.show-slide {
    opacity: 1;
  }
`

const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  max-width: 1140px;
  transform: translate(-50%, -50%);
  width: 100%;
`

const Nav = styled.div`
  position: absolute;
  left: 50%;
  bottom: ${gutter * 2}px;
  z-index: 20;
  display: flex;
  transform: translateX(-50%);
`
const NavItem = styled.div`
  height: 15px;
  width: 15px;
  margin: 0 7px;
  background: white;
  border-radius: 50%;

  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: ${p => (p.active ? 1 : 0.5)};

  &:hover {
    opacity: ${p => (p.active ? 1 : 0.75)};
  }
`

const Slider = ({ slides }) => {
  const slideshow = useRef()
  const sliderEl = useRef()
  const [currIndex, setIndex] = useState(SlideShow.startIndex)

  const refs = useMemo(
    () => Array.from({ length: slides.length }, () => createRef()),
    [slides.length]
  )

  useEffect(() => {
    let swipeXStart = 0
    const selectors = {
      title: "[data-title]",
      image: "[data-image]",
      hashTag: "[data-hashtag]",
    }
    const onIndexChange = setIndex
    slideshow.current = new SlideShow(refs.map(ref => ref.current), {
      onIndexChange,
      selectors,
    })

    const onTouchStart = event => {
      swipeXStart = event.changedTouches[0].screenX
    }

    const onTouchEnd = event => {
      const xDiff = event.changedTouches[0].screenX - swipeXStart
      const sensitivity = 25

      if (xDiff < -sensitivity) {
        slideshow.current.next()
      } else if (xDiff > sensitivity) {
        slideshow.current.previous()
      }
    }

    sliderEl.current.addEventListener("touchstart", onTouchStart, false)
    sliderEl.current.addEventListener("touchend", onTouchEnd, false)

    slideshow.current.init()
    return () => {
      document.removeEventListener("touchstart", onTouchStart)
      document.removeEventListener("touchend", onTouchEnd)
      slideshow.current.destroy()
    }
  }, [])

  const onLoadFirstImage = () => {
    slideshow.current.init()
  }

  return (
    <Inner ref={sliderEl}>
      {slides.map((slide, i) => (
        <Item
          className={i === SlideShow.startIndex && "show-slide"}
          ref={refs[i]}
          key={i}
        >
          <StyledImage
            // onLoad={() => i === SlideShow.startIndex && onLoadFirstImage()}
            critical={i === SlideShow.startIndex}
            data-image
            fluid={get(
              slide,
              "slide.document[0].data.image.localFile.childImageSharp.fluid"
            )}
          />
          <Content>
            <Title
              data-title
              dangerouslySetInnerHTML={{
                __html: get(slide, "slide.document[0].data.title.html"),
              }}
            ></Title>
          </Content>
        </Item>
      ))}
      <HashTag data-hashtag>#ridejip</HashTag>
      <Nav>
        {slides.map((_, i) => (
          <NavItem
            active={i === currIndex}
            key={i}
            onClick={() => slideshow.current.goToIndex(i)}
          />
        ))}
      </Nav>
    </Inner>
  )
}

export default Slider
