import anime from "animejs"

const SLIDE_TIMER = 3200

class SlideShow {
  constructor(elements, { selectors, onIndexChange }) {
    this.items = elements
    this.index = 0
    this.selectors = selectors
    this.inProgress = false
    this.onIndexChange = onIndexChange
    this.destroyed = false
  }

  static get startIndex() {
    return 0
  }

  destroy = () => {
    this.destroyed = true
  }

  init() {
    this.showCurrentTitle()
    this.timer = setTimeout(this.next, SLIDE_TIMER)
  }

  goToIndex = async index => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    if (index === this.index) return
    else if (this.inProgress) return

    this.inProgress = true

    await this.hideCurrentTitle()
    if (this.destroyed) return
    this.items[this.index].classList.remove("show-slide")

    this.onIndexChange(index)
    this.index = index

    this.items[this.index].classList.add("show-slide")

    await this.showCurrentTitle()
    this.inProgress = false
    this.timer = setTimeout(this.next, SLIDE_TIMER)
  }

  next = () => {
    const nextIndex = this.items[this.index + 1] ? this.index + 1 : 0
    this.goToIndex(nextIndex)
  }

  previous = () => {
    const prevIndex = this.items[this.index - 1]
      ? this.index - 1
      : this.items.length - 1

    this.goToIndex(prevIndex)
  }

  async showCurrentTitle() {
    const item = this.items[this.index]
    const target = item.querySelector(this.selectors.title)

    const mod = await import("splitting")
    const Splitting = mod.default

    const results = Splitting({ target, by: "lines" })
    const { words } = results[0]

    return new Promise(resolve => {
      if (this.destroyed) return resolve()
      target.style.opacity = "1"

      anime({
        targets: words,
        // scale: [0.3, 1],
        opacity: [0, 1],
        translateY: ["100%", 0],
        delay: (a, i) => i * 20,
        complete: () => {
          resolve()
          const hashtag = document.querySelector(this.selectors.hashTag)
          if (hashtag) hashtag.style.opacity = "1"
        },
      })

      target.style.opacity = "1"
    })
  }

  hideCurrentTitle() {
    const item = this.items[this.index]
    const target = item.querySelector(this.selectors.title)
    if (this.destroyed) return

    document.querySelector(this.selectors.hashTag).style.opacity = "0"

    return new Promise(resolve => {
      anime({
        targets: target,
        opacity: [1, 0],
        complete: resolve,
        easing: "cubicBezier(.5, .05, .1, .3)",
        duration: 450,
      })
    })
  }
}

export default SlideShow
