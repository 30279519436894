import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import styled from "styled-components"
import Block from "../components/Block"
import AppBlock from "../components/AppBlock"
import { gutter } from "../vars"
import Seo from "../components/Seo"
import LocationBlock from "../components/LocationBlock"
import SliderBlock from "../components/SliderBlock"

const Page = styled.div``
const Item = styled.div``

const components = {
  block: Block,
  appblock: AppBlock,
  locationblock: LocationBlock,
  sliderblock: SliderBlock,
}

const comp = (type, data, index, count) => {
  const Component = components[type]

  if (!Component) return null

  return <Component isLast={index + 1 === count} index={index} data={data} />
}

export default ({ data }) => {
  const blocks = get(data, "prismicHome.data.blocks", [])
    .map(block => ({
      ...get(block, "block.document[0].data", {}),
      type: get(block, "block.document[0].type"),
    }))
    .map(block => ({
      ...block,
      suffix: get(block, "suffix.document[0].data"),
    }))
    .filter(Boolean)
  return (
    <Page>
      {blocks.map((block, i) => (
        <Item key={i}>{comp(block.type, block, i, blocks.length)}</Item>
      ))}
      <Seo />
    </Page>
  )
}

export const query = graphql`
  query($locale: String) {
    prismicHome(lang: { eq: $locale }) {
      data {
        seo {
          document {
            data {
              title {
                text
              }
              image {
                url
              }
              description {
                text
              }
            }
          }
        }
        blocks {
          block {
            document {
              ... on PrismicSliderblock {
                type

                data {
                  text {
                    html
                  }
                  title {
                    html
                  }                  
                  slides {
                    slide {
                      document {
                        data {
                          title {
                            html
                          }
                          image {
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 1900, quality: 100) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                              }
                            }
                            url
                            alt
                          }
                        }
                      }
                    }
                  }
                  menu_item

                  suffix {
                    document {
                      ... on PrismicApplinks {
                        type
                        data {
                          menu_item
                          heading {
                            text
                            html
                          }
                          ios_icon {
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 1140, quality: 100) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                              }
                            }
                          }

                          google_play_icon {
                            localFile {
                              childImageSharp {
                                fluid(maxWidth: 1140, quality: 100) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              ... on PrismicBlock {
                type

                data {
                  headline {
                    text
                  }
                  ingress {
                    text
                    html
                  }
                  body {
                    html
                    text
                  }
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1140, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    url
                    alt
                  }
                  menu_item
                }
              }

              ... on PrismicLocationblock {
                type
                data {
                  menu_item
                  title {
                    html
                    text
                  }
                }
              }

              ... on PrismicAppblock {
                type
                data {
                  heading {
                    text
                  }
                  menu_item

                  steps {
                    title {
                      text
                    }
                    body {
                      text
                    }
                    image {
                      url
                      alt
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 450, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
