import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import { gutter } from "../vars"
import scrollMonitor from "scrollmonitor"

const DELAY_IN_MS = 60
const Wrapper = styled.div``
const Part = styled.div`
  transition: transform 0.45s ease-in, opacity 0.45s ease-in;
  transform: translateY(${p => (p.show ? 0 : 15)}px);
  opacity: ${p => (p.show ? 1 : 0)};
`

const AnimateIns = ({ children }) => {
  const container = useRef()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const watcher = scrollMonitor.create(container.current, -200)

    watcher.enterViewport(() => {
      watcher.destroy()

      setShow(true)
    })

    return () => {
      watcher.destroy()
    }
  }, [])

  const childNodes = React.Children.map(children, child => child).filter(
    Boolean
  )

  const nodes = childNodes.map((child, i) => (
    <Part
      show={show}
      style={{ transitionDelay: `${i * DELAY_IN_MS}ms` }}
      className="js-reset"
      key={i}
    >
      {child}
    </Part>
  ))

  return <Wrapper ref={container}>{nodes}</Wrapper>
}
export default AnimateIns
