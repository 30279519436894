import React, { useEffect, useMemo, useRef, createRef, useState } from "react"
import styled from "styled-components"
import AppLinks from "./AppLinks"
import Slider from "./Slider"
import { gutter } from "../vars"
import media from "../media"
import get from "lodash/get"

const Container = styled.div``
const Title = styled.div`
  text-align: center;
  padding-top: ${gutter * 4}px;
`
const Text = styled.div`
  text-align: center;
  padding-top: ${gutter * 2}px;  
`
const Inner = styled.div`
  max-width: 900px;
  margin: 0 auto;
  ${media.phone`
    margin: 0 1rem;
  `}
`

const SliderBlock = props => {
  const { suffix, slides, title, text } = props.data
  const titleHTML = get(title, "html")
  const textHTML = get(text, "html")
  return (
    <Container>
      <Slider slides={slides} />
      <Inner>
        <Title dangerouslySetInnerHTML={{ __html: titleHTML }}></Title>
        <Text dangerouslySetInnerHTML={{ __html: textHTML }}></Text>
      </Inner>
      {suffix && <AppLinks data={suffix} />}
    </Container>
  )
}

export default SliderBlock
