import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useSettingContext } from "../settingsContext"
import { gutter } from "../vars"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "./Image"
import AnimateIns from "./AnimateIns"
import Grid from "./Grid"
import get from "lodash/get"
import { getIdFromString } from "../utils"
import Cookies from "js-cookie"
import isMobile from 'ismobilejs';
import CitySelector from "./CitySelector";

const Wrapper = styled.div`
  text-align: center;
  position: relative;
`

const Divider = styled.div`
  height: 1px;
  background: white;

  margin-top: ${gutter * 4}px;
`

const Option = styled.div`
  margin-right: ${gutter * 2}px;
  padding-right: ${gutter * 2}px;
  position: relative;
  cursor: pointer;
  color: ${p => (p.selected ? "#a3f45c" : "white")};
  transition: color 0.3s ease;
  font-size: 22px;

  &:after {
    content: "";
    height: 100%;
    width: 1px;
    background: white;
    position: absolute;
    right: 0;
  }

  &:last-child {
    margin-right: 0;
    padding-right: 0;

    &:after {
      display: none;
    }
  }
`

const Top = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-bottom: ${gutter * 2}px;
`

const Title = styled.h1`
  margin-bottom: ${gutter * 2}px;
  padding-top: ${gutter * 4}px;
`

const MapImage = styled(Image)`
  position: relative; 
  z-index: 1;
`

const PriceInfo = styled.div`
  margin: ${gutter * 5}px auto;
  max-width: 900px;
  position: relative; 
  z-index: 1;
`

const LocationBlock = props => {
  const t = useSettingContext()
  const data = useStaticQuery(graphql`
    {
      allPrismicCity {
        nodes {
          lang
          id
          data {
            name {
              text
            }

            price_text {
              html
            }
            price_title {
              text
            }

            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1140, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              url
              alt
            }
          }
        }
      }
    }
  `)

  const nodes = data.allPrismicCity.nodes
    .filter(node => node.lang === t.locale)
    .sort((a, b) => a.data.name.text.localeCompare(b.data.name.text))

  const [selected, setSelected] = useState(nodes[0])

  const priceTitle = get(selected, "data.price_title.text")
  const priceText = get(selected, "data.price_text.html")

  const onSelect = node => {
    setSelected(node)
    Cookies.set("selectedCity", node.id)
  }

  const DesktopTop = () => {
    return nodes.map((node, i) => (
      <Option
        selected={selected.id === node.id}
        onClick={() => onSelect(node)}
        key={i}
      >
        {node.data.name.text}
      </Option>
    ))
  }

  const MobileTop = () => {
    return <CitySelector nodes={nodes} onSelect={onSelect} selected={selected} />
  }

  useEffect(() => {
    const cachedCityValue = Cookies.get("selectedCity")
    const cachedCity = nodes.find(node => node.id == cachedCityValue)
    if (cachedCity) onSelect(cachedCity)
  }, [])

  return (
    <Grid>
      <Wrapper id={getIdFromString(props.data.menu_item)}>
        <AnimateIns>
          <Title>{props.data.title.text}</Title>
          <Top>{isMobile().any ? <MobileTop /> : <DesktopTop />}</Top>
          <MapImage image={selected.data.image} />

          <PriceInfo>
            <h3>{priceTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: priceText }}></div>
          </PriceInfo>
          <Divider></Divider>
        </AnimateIns>
      </Wrapper>
    </Grid>
  )
}

export default LocationBlock
