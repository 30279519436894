import React from "react"
import styled from "styled-components"
import media from "../media"

const SecondaryTitle = styled.h2`
  font-weight: bold;
  font-size: 42px;
  line-height: 1.1;

  ${media.phone`
  font-size: 34px;  
  `}
`

export default SecondaryTitle
