import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import Image from "./Image"
import { gutter } from "../vars"
import Ingress from "./Ingress"
import { getIdFromString } from "../utils"
import AppLinks from "./AppLinks"
import get from "lodash/get"
import scrollMonitor from "scrollmonitor"
import AnimateIns from "./AnimateIns"
import { useSettingContext } from "../settingsContext"
import Grid from "./Grid"

const Container = styled.div`
  text-align: center;
`
const Inner = styled.div`
  max-width: 900px;
  margin: 0 auto;
`

const Divider = styled.div`
  height: 1px;
  background: white;

  margin-top: ${gutter * 4}px;
`
const Title = styled.h1`
  margin-bottom: ${gutter * 2}px;
  padding-top: ${gutter * 4}px;
`
const Body = styled.div`
  margin-bottom: ${gutter * 2}px;
`

const StyledIngress = styled(Ingress)`
  margin-top: ${gutter * 4}px;
`

const scrollOffset = -100

const FaqIngress = props => {
  const t = useSettingContext()
  // TODO
  const html = props.dangerouslySetInnerHTML.__html
  const str = html.replace("https://#faq", `${t.pathPrefix}faq`)

  return <StyledIngress dangerouslySetInnerHTML={{ __html: str }} />
}

const Block = ({ data, index, isLast }) => {
  const container = useRef()
  const { headline, body, ingress, image, suffix, menu_item } = data
  const [show, setShow] = useState(false)
  const fallbackAlt =
    get(headline, "text") || get(ingress, "text") || get(body, "text")

  useEffect(() => {
    const watcher = scrollMonitor.create(container.current, scrollOffset)

    watcher.enterViewport(() => {
      watcher.destroy()

      setShow(true)
    })

    return () => {
      watcher.destroy()
    }
  }, [])

  const IngressNode = isLast ? FaqIngress : Ingress

  return (
    <Grid>
      <Container ref={container} id={getIdFromString(menu_item)}>
        <AnimateIns>
          {index === 0 && <Image fallbackAlt={fallbackAlt} image={image} />}
          {headline && headline.text && (
            <Inner>
              <Title>{headline.text}</Title>
            </Inner>
          )}
          {ingress && ingress.text && (
            <Inner>
              <IngressNode dangerouslySetInnerHTML={{ __html: ingress.html }} />
            </Inner>
          )}
          {body && body.html && (
            <Inner>
              <Body dangerouslySetInnerHTML={{ __html: body.html }} />
            </Inner>
          )}
          {index !== 0 && <Image fallbackAlt={fallbackAlt} image={image} />}
          {suffix && <AppLinks data={suffix} />}
          {<Divider />}
        </AnimateIns>
      </Container>
    </Grid>
  )
}

export default Block
