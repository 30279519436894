import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import SecondaryTitle from "./SecondaryTitle"
import { gutter } from "../vars"
import Grid from "./Grid"
import { getIdFromString } from "../utils"
import media from "../media"

const Container = styled.div`
  padding-top: ${gutter * 7}px;
  text-align: center;

  ${media.phone`
  padding-top: ${gutter * 3}px;
  `}
`

const Icons = styled.div`
  display: flex;
  margin-top: ${gutter * 2}px;
  justify-content: center;

  > div {
    max-width: 230px;
    margin: 0 ${gutter}px;
    flex: 1;
  }

  div:nth-child(1) {
    img,
    picture {
      object-position: right !important;
    }
  }

  div:nth-child(2) {
    img,
    picture {
      object-position: left !important;
    }
  }

  a {
    transition: transform 0.3s ease;
    display: block;
    max-height: 65px;

    ${media.phone`
      height: 45px;
    `}

    > div {
      height: 100%;
      max-height: 65px;

      ${media.phone`
      height: 45px;
    `}
    }

    img,
    picture {
      object-fit: contain !important;
    }

    &:hover {
      transform: scale(1.02);
    }
  }
`

const iosLink = "https://go.onelink.me/GkyM/962c48af"
const playStoreLink = "https://go.onelink.me/GkyM/962c48af"

const AppLinks = ({ data }) => {
  const { heading, ios_icon, google_play_icon, menu_item } = data

  return (
    <Grid>
      <Container id={getIdFromString(menu_item)}>
        {heading && (
          <SecondaryTitle dangerouslySetInnerHTML={{ __html: heading.html }} />
        )}
        <Icons>
          {ios_icon && (
            <div>
              {" "}
              <a rel="noopener noreferrer" target="_blank" href={iosLink}>
                <Image
                  fluid={ios_icon.localFile.childImageSharp.fluid}
                  alt="App Store"
                />
              </a>
            </div>
          )}

          {google_play_icon && (
            <div>
              <a rel="noopener noreferrer" target="_blank" href={playStoreLink}>
                <Image
                  {...google_play_icon.localFile.childImageSharp}
                  alt="Google Play Store"
                />
              </a>
            </div>
          )}
        </Icons>
      </Container>
    </Grid>
  )
}

export default AppLinks
