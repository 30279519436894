import React from "react"
import styled from "styled-components"
import { gutter } from "../vars"

const CitySelect = styled.select`  
  padding-bottom: ${gutter}px;
  position: relative;
  cursor: pointer;
  color: ${p => (p.selected ? "#a3f45c" : "white")};
  transition: color 0.3s ease;
  font-size: 22px;
  width: 40%;
  border: 0;
  border-bottom: 1px solid white;
  background: none;
  border-radius: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='none' d='M0 0h24v24H0V0z'/><path fill='white' d='M7 10l5 5 5-5H7z'/></svg>") no-repeat;
  background-position: right 5px top 50%;  
  font-family: "Pangram", Helvetica, "Arial", sans-serif;
`

const SelectOption = styled.option`
  color: ${p => (p.selected ? "#a3f45c" : "white")};
  transition: color 0.3s ease;
  font-size: 22px;
`

const CitySelector = ({ nodes, onSelect, selected = {} }) => {

  const onSelectChange = nodeId => onSelect(nodes.find(node => node.id === nodeId))

  return <CitySelect onChange={e => onSelectChange(e.currentTarget.value)}>
    {nodes.map((node, i) => (
      <SelectOption
        selected={selected.id === node.id}
        value={node.id}
        key={i}
      >
        {node.data.name.text}
      </SelectOption>
    ))}
  </CitySelect>
}


export default CitySelector